import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

/**
 * @todo Add breadcrumps
 */
const OrderFormPage = ({ data }) => {
  const { story } = data

  // Ignore storyblok content. Instead use OrderFormBlock
  //const content = JSON.parse(story.content)
  const content = {
    body: [
      {
        component: "order_form_block",
        _uid: "order_form_block"
      }
    ]
  }

  return (
    <Layout>
      <SEO content={content} />
      <div className="bg-white">
        <BodyBlocks body={content.body} />
      </div>
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(
      full_slug: { eq: "form-bestellvorgang" }
    ) {
      name
      content
      full_slug
    }
  }
`

export default OrderFormPage
